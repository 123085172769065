const idPortalApi = "8e57e87a3602bb6e6f8c546645c868e92b8cc50e";

module.exports = {
  idPortalApi: `${idPortalApi}`,
  // Produção
  urlApi: `https://api-portais-workcenter.com.br/${idPortalApi}`,
  //urlApi: `http://ec2-54-232-59-57.sa-east-1.compute.amazonaws.com:8004/${idPortalApi}`,
  // Local
  // urlApi: `http://localhost:8004/${idPortalApi}`,

  //config botões e imagens
  urlADM: `https://administracaopublica.com.br`,

  dnsPortal: "novacolinas.ma.gov.br",
  NomePortal: "Nova Colinas",
  ufPortal: "MA",
  NomeTipoPortal: "Prefeitura Municipal", //Prefeitura ou Câmara
  TipoPortal: 3, //3 = Prefeitura 4=Câmara

  //Variável em teste (newsViewRoute)
  newsViewRoute: false, //Essa opção informa se a rota de visualização de uma única noticia está disponível (Raposa gera as noticias pelo lado do servidor com nextjs)

  countNewsHome: 12, //quantidade de noticias na home

  RoutesDaily: true, //Deixa ativado as rotas para a pagina de diario

  link: "http://www.transparenciadministrativa.com.br", //Onde o token abaixo ser[a utilizado]
  linkArquivos: "http://www.transparenciadministrativa.com.br/portalimagem", //Onde o token abaixo ser[a utilizado]
  tokenLink: `${idPortalApi}`, //Token para acessar o WWW.transparência

  urlEmDeploy: "https://novacolinas.ma.gov.br", //usada para link de compartilhamento
  imagemURL: "https://cdn.administracaopublica.com.br/complementares/", //link para a imagem
  //imagemURL: 'http://localhost:8004/uploads/', //link para a imagem

  corIcones: "#000080",

  link_youtube: `#`,
  link_localize: `https://maps.app.goo.gl/LKWMQVEdWYJ9HKoP7`,
  //Mapa da pagina Municipio/Mapa
  link_mapa: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7918.127587014326!2d-46.26227664773053!3d-7.118606077575395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x92d683b6d2353f21%3A0x886dce526b0c6de1!2sNova%20Colinas%2C%20State%20of%20Maranh%C3%A3o%2C%2065808-000!5e0!3m2!1sen!2sbr!4v1736364451255!5m2!1sen!2sbr`,
};
